// Media queries can't be used as native CSS variables (yet)
$bp-mobile-s: 320px !default;
$bp-mobile-m: 375px !default;
$bp-mobile-l: 414px !default;
$bp-tablet-s: 562px !default;
$bp-tablet-m: 768px !default;
$bp-tablet-l: 920px !default;
$bp-desktop-1-xs: 1024px !default;
$bp-desktop-s: 1120px !default;
$bp-mobile-nav: 1240px !default;
$bp-desktop-m: 1280px !default;
$bp-desktop-l: 1440px !default;
$bp-desktop-1-xl: 1650px !default;
$bp-desktop-2-xl: 1920px !default;

%heading {
  font-weight: 600;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-tight);
  letter-spacing: var(--tracking-normal);
}

%paragraph {
  font-weight: 500;
  color: var(--sweet-liquorice-750);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%quote {
  font-weight: 500;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%link {
  font-weight: 500;
  text-decoration: underline;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-none);
  letter-spacing: var(--tracking-normal);
}

%button {
  font-weight: 600;
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

.inversed {
  %heading {
    color: var(--white-000);
  }

  %paragraph,
  %quote,
  %link {
    color: var(--sweet-liquorice-300);
  }
}

@mixin h1 {
  @extend %heading;
  font-size: var(--heading-6-xl);
}

@mixin h2 {
  @extend %heading;
  font-size: var(--heading-3-xl);
}

@mixin h3 {
  @extend %heading;
  font-size: var(--heading-2-xl);
}

@mixin h4 {
  @extend %heading;
  font-size: var(--heading-1-xl);
}

@mixin h5 {
  @extend %heading;
  font-size: var(--heading-l);
}

@mixin h6 {
  @extend %heading;
  font-size: var(--heading-m);
}

@mixin streamer {
  @extend %paragraph;
  font-size: var(--streamer-size);
  font-weight: 500;
}

@mixin quote-1-xl {
  @extend %quote;
  font-size: var(--heading-2-xl);
}

@mixin quote-l {
  @extend %quote;
  font-size: var(--heading-l);
}

@mixin body-s {
  @extend %paragraph;
  font-size: var(--body-s);
}

@mixin body-m {
  @extend %paragraph;
  font-size: var(--body-m);
}

@mixin body-l {
  @extend %paragraph;
  font-size: var(--body-l);
}

@mixin body-1-xl {
  @extend %paragraph;
  font-size: var(--body-1-xl);
}

@mixin body-2-xl {
  @extend %paragraph;
  font-size: var(--body-2-xl);
}

@mixin textlink-l {
  @extend %link;
  font-weight: 600;
}

@mixin textlink-m {
  @extend %link;
}

@mixin textlink-s {
  @extend %link;
}

@mixin caption {
  font-weight: 500;
}

@mixin eyebrow {
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--heading-s);
  color: var(--sweet-liquorice-500);
}

@mixin footerlink-m {
  color: var(--sweet-liquorice-100);
}

@mixin footerlink-s {
  color: var(--sweet-liquorice-100);
}

@mixin button-l {
  @extend %button;
  font-size: var(--body-l);
}

@mixin button-m {
  @extend %button;
  font-size: var(--body-m);
}

@mixin button-s {
  @extend %button;
  font-size: var(--body-s);
}

// DEFAULT

// Heavy
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Heavy.woff2') format('woff2'),
    url('/fonts/Dazzed-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: optional;
}

// Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Bold.woff2') format('woff2'),
    url('/fonts/Dazzed-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: optional;
}

// Semi-Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBold.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: optional;
}

// Medium
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Medium.woff2') format('woff2'),
    url('/fonts/Dazzed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: optional;
}

// ITALIC

// Heavy Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-HeavyItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: optional;
}

// Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-BoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: optional;
}

// Semi-Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: optional;
}

// Medium Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-MediumItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: optional;
}

@keyframes progress {
  0% {
    opacity: 1;
    transform: scaleX(0);
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

.header {
  position: relative;
}

.block {
  margin-bottom: var(--spacing-6-xl);
  position: relative;
}

.title {
  margin-bottom: var(--spacing-4-xl);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .all-services {
    margin-top: var(--spacing-m);
  }
}

.backgrounds {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;

  @media screen and (max-width: $bp-tablet-l) {
    display: none;
  }

  div {
    height: 100%;
    transition: background var(--fast-transition) linear;
  }

  .left {
    background: var(--white-000);
  }

  .right {
    border-radius: var(--radius-1-xs);
    background: var(--background);
  }
}

.services {
  padding: var(--spacing-m) 0;

  @media screen and (max-width: $bp-desktop-s) {
    grid-column: span 5 / span 5;
    grid-column-start: 1;
  }

  @media screen and (max-width: $bp-tablet-l) {
    grid-column: 1/-1;
    padding: 0;
  }

  .item:first-of-type {
    border-radius: 2px 2px 0 0;
    overflow: hidden;
  }

  .item:last-of-type {
    overflow: hidden;
    border-radius: 0 0 2px 2px;
  }
}

.item {
  --bg: transparent;

  h3 {
    margin-bottom: var(--spacing-s);
  }

  .text {
    width: 80%;

    @media screen and (max-width: $bp-tablet-l) {
      width: 50%;
      padding:
        var(--spacing-1-xl)
        0
        var(--spacing-1-xl)
        var(--spacing-1-xl);
    }

    @media screen and (max-width: $bp-tablet-s) {
      width: 80%;
    }
  }

  .icon {
    --timing: .45s;
    --delay: var(--timing);
    opacity: 1;
    display: flex;
    margin-inline: var(--spacing-2-xl) 0;
    transition: transform var(--slow-transition) var(--ease-out);

    &.active {
      transition:
        opacity var(--timing) linear,
        transform var(--slow-transition) var(--ease-out);
      opacity: 1;
    }

    @media screen and (max-width: $bp-tablet-l) {
      order: 3;
      margin-inline: 0 var(--spacing-2-xl);
    }
  }

  @media screen and (min-width: $bp-tablet-l) {
    &:hover {
      .icon {
        transform: translate3d(-16px, 0, 0);
      }
    }
  }
}

.wrapper {
  --animation-duration: .45s;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-1-xl) 0;
  justify-content: space-between;
  overflow-y: hidden;

  @media screen and (max-width: $bp-tablet-l) {
    background-color: var(--bg);
    transition: background-color var(--animation-duration) linear;
    padding: 0;
  }
}

.progress {
  background: var(--background);
  height: 1px;
  position: relative;
  transition: background var(--fast-transition) linear;
  width: 100%;

  span {
    background: var(--active);
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform-origin: left;
    transform: scaleX(0);
    transition: opacity var(--fast-transition) linear;
    width: 100%;
  }

  .start {
    animation: progress 5s linear forwards;
  }

  .stop {
    animation: none;
    opacity: 1;
    transform: scaleX(1);
  }
}

.area {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $bp-tablet-l) {
    display: none;
  }

  svg {
    position: absolute;
    width: 220px;
    height: 230px;
    fill: none;
    stroke: var(--sweet-liquorice-900);

    // stylelint-disable stylistic/max-line-length
    // ! Don't remove this (yet) this fixes a bug when navigating between pages
    // ? This is the value that is set by framer motion automatically.
    // ? Framer motion however fails to set the proper value when navigating between pages.
    // ? Leading to a transform origin of 0, we overwrite this here with important.
    // ? This causes the behavior to be correct when navigating between pages.
    // ! Also don't round these numbers, they need to be exactly the following!
    // stylelint-enable stylistic/max-line-length
    transform-origin: 82.762px 86.0792px 0 !important;
  }

  .illus-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 444px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
    }
  }

  img {
    margin: auto;
    z-index: -1;
  }
}

.button {
  margin-top: var(--spacing-1-xl);
}

.mobile-image {
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  margin-inline: auto;
  padding-block: var(--spacing-2-xl);

  @media screen and (min-width: $bp-tablet-s) {
    width: 40%;
    order: 2;
  }

  .illu {
    width: auto;
    object-fit: cover;
    height: calc(272px - 2 * var(--spacing-2-xl));

    @media screen and (min-width: $bp-tablet-s) {
      min-height: 65px;
    }
  }
}
